<template>
  <div class="book">
    <div class="fs-flex fs-flex-between">
      <!-- 目录 -->
      <div class="book-nav">
        <!-- Tabs -->
        <div class="book-tabs">
          <!-- <div :class="tabs==0&&'book-tabs-active'" @click="tabs=0">目录</div> -->
          <div :class="tabs==1&&'book-tabs-active'" @click="tabs=1">缩略图</div>
        </div>

        <template v-if="tabs==0" >
          <!-- 大纲页码是从 1 开始，因此作为页码不需要额外处理  -->
          <Catalogue :data="pdfData.menu?.menuItems" @select="gotoPage" />
        </template >
        <!-- 缩略图 -->
        <template v-else>
          <div class="smallImg">
            <template v-for="(item,index) in thumbnails" :key="index">
              <div class="thumbnail-index">

                <!-- 图片索引是从 0 开始，因此作为页码要加上 1  -->
                <div class="thumbnail-index-pages" @click="gotoPage(item.leftIndex + 1)">
                  <div class="thumbnail-index-left" >
                    <img :src="item.leftThumbnail" />
                  </div>
                  <div v-if="item.rightThumbnail" class="thumbnail-index-right" >
                    <img :src="item.rightThumbnail" />
                  </div>
                </div>

                <!-- 图片索引是从 0 开始，因此作为页码要加上 1  -->
                <span  @click="gotoPage(item.leftIndex + 1)">{{item.leftIndex + 1}}{{item.rightIndex ? "-" + (item.rightIndex + 1) : ""}}</span>
              </div>
            </template>
          </div>
        </template>
      </div>
      <!-- 图书 -->
      <div class="book-main fs-flex fs-flex-column fs-flex-between">
        <!-- 翻页 -->
        <div class="book-body fs-flex fs-flex-between fs-flex-align-center">
          <el-icon class="page-turner" :size="'26px'" @click="gotoPreviousPage">
            <ArrowLeftBold />
          </el-icon>
          <div class="bookview fs-flex fs-flex-center fs-flex-align-center">
            <flipbook ref="flipbook" class="flipbook shadow" :pages='pages' :zooms="[1]" :front-page="false" :single-page="!doublePage" :double-page="doublePage" @flipped="onPageFlipped">
            </flipbook>
          </div>
          <el-icon class="page-turner" :size="'26px'" @click="gotoNextPage">
            <ArrowRightBold />
          </el-icon>
        </div>
        <!-- 操作 -->
        <div class="book-action fs-flex fs-flex-end">
          <el-link v-loading="printLoading" :underline="false" style="cursor:pointer;" @click="loadAndPrintPDF" >
            <div class="book-action-btn">
              <img src="@/assets/icon/book.png" />
            </div>
          </el-link>
          <el-link :underline="false"  target="_blank" download="" :href="pdfUrl">
            <div class="book-action-btn">
              <img src="@/assets/icon/download.png" />
            </div>
          </el-link>
          <div><span>页</span>
            <el-input-number class="page-num-input" :modelValue="leftPageNum" :controls="false" :min="1" :max="pageCount" @keyup.enter="leftGotTo" @change="leftPageNum=$event" @blur="recoverPageNum"/>
            <span>-</span>
            <el-input-number class="page-num-input" :modelValue="rightPageNum" :controls="false" :min="2" :max="pageCount"  @keyup.enter="rightGotTo" @change="rightPageNum=$event" @blur="recoverPageNum" />
            <span>/ {{pages ? pages.length : 0}}</span>
          </div>
        </div>
      </div>

    </div>

  </div>
<!-- 
  <iframe id="printer" ></iframe> -->

  <!-- <a id="printer" :src="printerUrl" style="position:fixed; top: -10px; left: -10px; width:0px; height:0px; visibility:hidden"></a> -->

</template>
<script>
// import '@/utils/turn.js'
import {inject} from "vue";
import request from "@/utils/request";
import { getUrlParams } from "@/utils";
import Catalogue from "@/components/Catalogue";
import Flipbook from 'flexue-flipbook'
// import pdfjsLib from 'pdfjs-dist'

import axios from 'axios'
const httpRequest = axios.create({
  withCredentials: false
})

export default {
  name: 'Book',
  components: {
    Catalogue,
    Flipbook
  },


  setup() {
    // 指示当前页的名称，以便使菜单显示高亮；
    const pageIndicator = inject("pageIndicator");
    pageIndicator.currentPageName="service";
    console.log("inject pageIndicator[service pdf books] ...", pageIndicator);
  }, 


  data() {
    return {
      pdfData: {},
      tabs: 1,
      pages:[],
      thumbnails:[],// 缩略图索引列表；

      doublePage: true,
      pageCount : 2,
      leftPageNum: 1, // 当前页码；从 1 开始；
      rightPageNum: 2,

      printerUrl:"/pdfprint",

      printLoading: false
    }
  },

  methods: {

    leftGotTo(){
      this.leftPageNum = this.leftPageNum - 1 + this.leftPageNum % 2;
      this.rightPageNum = this.leftPageNum + 1;
      this.gotoPage(this.leftPageNum);
    },

    rightGotTo(){
      this.leftPageNum = this.rightPageNum -1 + this.rightPageNum%2
      this.rightPageNum = this.leftPageNum + 1;
      this.gotoPage(this.leftPageNum);
    },

    onPageFlipped(pageNum){
      if (this.leftPageNum != pageNum) {
        this.leftPageNum = pageNum;
        this.rightPageNum = pageNum+1;
      }
    },

    recoverPageNum(){
      const flipbook = this.$refs.flipbook;
      this.leftPageNum = flipbook.page;
      this.rightPageNum = this.leftPageNum + 1;
    },

    /**
     * 跳转到指定的页码；
     * 
     * 注：页码从 1 开始；
     * 
     * @param {Number} pageNum 页码，从 1 开始；
     */
    gotoPage(pageNum){
      const flipbook = this.$refs.flipbook;
      flipbook.goToPage(pageNum);
    },

    gotoPreviousPage(){
      const flipbook = this.$refs.flipbook;
      flipbook.flipLeft();
      // this.gotoPage(this.leftPageNum -1);
    },  

    gotoNextPage(){
      const flipbook = this.$refs.flipbook;
      flipbook.flipRight();
      // this.gotoPage(this.leftPageNum + 1);
    },  

    loadPDF(){
      const THIS = this;
      request({
        url: "/api/ui/pdf/" + getUrlParams('id'),
        method: "get",
      })
        .then((resp) => {
          if (resp.code == 0 && resp.data) {
            THIS.pdfData = resp.data;
            THIS.printerUrl = "/pdfprint?pdf=" + resp.data.pdf.id;

            if (resp.data.picsList) {
              const pageList = resp.data.picsList;
              THIS.pages = pageList.map(item=>'/api/ffs/file/'+item.bigImage);
              if (THIS.pages.length > 0) {
                THIS.pageCount = THIS.pages.length;
                THIS.leftPageNum = 1;
                THIS.rightPageNum = 2;
              }else{
                THIS.leftPageNum = 0;
                THIS.rightPageNum = 0;
                THIS.pageCount = 0;
              }

              const thumbnailIndexes = [];
              for (let i = 0; i < pageList.length; ) {
                const page1 = pageList[i];
                const page2 = i+1 < pageList.length ? pageList[i+1] : undefined;
                const index = {
                  leftIndex: i,
                  leftThumbnail: '/api/ffs/file/' + page1.smallImage + "?thumbnail=true",
                  leftPage: '/api/ffs/file/' + page1.bigImage,

                  rightIndex: page2 ? i+1 : undefined,
                  rightThumbnail: page2 ?  ('/api/ffs/file/' + page2.smallImage + "?thumbnail=true") :undefined,
                  rightPage: page2 ? ('/api/ffs/file/' + page2.bigImage) : undefined,
                };
                thumbnailIndexes.push(index);

                i += 2;
              }
              THIS.thumbnails = thumbnailIndexes;
            }else{
              THIS.pages = undefined;
              THIS.thumbnails = undefined;

              THIS.pageCount = 0;
              THIS.leftPageNum = 0;
              THIS.rightPageNum = 0;
            }
            // this.onTurn();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    openPrinter(){
      document.getElementById("printer").click();
    },

    loadAndPrintPDF(){

      // const loading = this.$loading();
      const THIS = this;
      THIS.printLoading = true;

      const fileId = this.pdfData.pdf.id;
      const url = `/api/ffs/file/${fileId}`;
      httpRequest({
        method:"get",
        url:url,
        responseType: "blob",
      }).then((resp) =>{
        let blob = new Blob([resp.data], {type:"application/pdf"});
        const blobUrl = URL.createObjectURL(blob);

        //将 pdf 内容加载到 iframe 中以便稍后进行打印；
        const iframe =  document.createElement('iframe'); 
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobUrl;
        iframe.onload = function() {
          setTimeout(function() {
            THIS.printLoading = false;

            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };

      }).catch(error => {
        console.error("ERROR ! -- ", error);
      })
    },

    /**
     * Turnjs 实现；
     */
    // onTurn() {
    //   this.$nextTick(() => {
    //     $("#flipbook").turn({
    //       width: 960,
    //       height: 600,
    //       singlePage:false,
    //       clickToZoom:false,
    //       display: 'double', //单页显示/双页显示  single/double
    //       elevation: 50,
    //       duration: 500, //翻页速度(毫秒), 默认600ms
    //       gradients: true, //翻页时的阴影渐变, 默认true
    //       autoCenter: true, //自动居中, 默认false
    //       acceleration: true, //硬件加速, 默认true, 如果是触摸设备设置为true
    //       page: 2, //设置当前显示第几页
    //       pages: this.pdfData.picsList.length, //总页数
    //       when: {
    //         //监听事件
    //         turning: function (e, page, view) {
    //           console.log("翻页。。。。", e, page, view)
    //           // 翻页前触发
    //         },
    //         turned: function (e, page) {
    //           console.log("翻页。。。。", e, page)
    //           // currentPage.value = page
    //           // 翻页后触发
    //         },
    //       },
    //     });
    //     // alert($('#flipbook').html()) ;
    //   })
    // }
  },

  computed: {
    pdfUrl() {
      return `/api/ffs/file/${this.pdfData?.pdf?.id}`;
    }
  },
  mounted() {
    this.loadPDF();
  },
}
</script>
<style lang="less" scoped>
.book {
  position: relative;
  background: #E2E2E2;
  padding-top: 70px;
  @cap: 6px;
  height: calc(~"100vh - @{cap}");

  &>div {
    height: 100%;

    .book-nav {
      min-width: 270px;
      padding: 70px 0 60px 0;
      background: #EFEFEF;

      .book-tabs {
        text-align: center;
        margin-bottom: 50px;

        &>div {
          width: 60px;
          display: inline-block;
          border: 1px solid #4F4F4F;

          &:hover {
            cursor: pointer;
          }
        }

        .book-tabs-active {
          background: #4F4F4F;
          color: #ffffff;

        }
      }

    }

    .book-main {
      flex: 1;

      .book-body {
        flex: 1;
        padding: 0 80px;

        .bookview {
          flex: 1;
          height: 100%;
          padding: 30px;

          img {
            height: 100%;
          }
        }
      }

      .book-action {
        height: 90px;
        padding: 0 100px 50px 100px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .book-action-btn {
          width: 25px;
          margin-right: 30px;
        }

        input {
          width: 53px;
          height: 27px;
          background: #727171;
          border: 0;
          font-size: 18px;
          /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
          font-weight: 400;
          color: #FFFFFF;
          line-height: 90px;
          margin: 0 6px;
        }
      }
    }
  }

}

.page-num-input{
  width: 64px;
  margin: 0 8px;
  
  /deep/.el-input__wrapper{
    background-color: #727171 !important;
  }
  /deep/.el-input__inner{
    color: #ffffff;
  }
}

.page-turner{
  cursor: pointer;
  color: #525253;
}

.smallImg {
  height: calc(100vh - 300px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  row-gap: 20px;
  
  .thumbnail-index{
    // width: 160px;
    // height: 113px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    flex-wrap: nowrap;
    box-sizing: border-box;

    cursor: pointer;

    .thumbnail-index-pages{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;

      cursor: pointer;

      .thumbnail-index-left{
        width: 100%;
      }
      .thumbnail-index-right{
        width: 100%;
      }

      img{
        width:90px;
      }
    }
  }

  // &>div {
  //   width: 100%;
  //   margin-bottom: 20px;
  //   text-align: center;

  //   div {
  //     display: flex;
  //     justify-content: center;
  //     gap: 20px;
  //     min-height: 115px;

  //     img {
  //       width: 70%;
  //     }
  //   }
  // }
}


.smallImg::-webkit-scrollbar {
      //display: none;
    width: 5px;
    height: 5px;
}

/*滚动条滑块*/
.smallImg::-webkit-scrollbar-thumb{
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #c7c4c4;
}
/*滚动条里面轨道*/
// .smallImg::-webkit-scrollbar-track {
//     box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
// }
/*滚动条的小边角*/
.smallImg::-webkit-scrollbar-corner {
    background: transparent;
}

.flipbook {
  width: 100%;
  height: 100%;
}
// /deep/.flipbook.shadow .bounding-box{
//   cursor: pointer !important;
//   /** 线性渐变表现页中间的书脊 */
//   background-image: linear-gradient(to right, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0), rgba(205, 205, 205, 0.1), rgba(83, 83, 83, 0.25),rgba(205, 205, 205, 0.1),rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
// }

</style>