<template>
  <!-- <div class="book-catalogue-item" :class="[open && 'open']">
    <div class="book-catalogue-title fs-flex fs-flex-between"  @click="select(data.pageNo)">
      <span  style="width:175px;display: flex;">
        <el-icon v-if="data.children" class="book-catalogue-arrow" @click="handOpen">
          <CaretRight />
        </el-icon>
        <el-icon v-else class="book-catalogue-arrow"> </el-icon>
        <span>{{ data.title }}</span> 
      </span>
      <span style="width:30px;margin-left: 5px;">{{ data.pageNo }}</span>
    </div>
    <div class="book-catalogue-child">
      <div v-for="(item, index) in data.children" :key="index" class="fs-flex fs-flex-between" @click="select(item.pageNo)">
        <span style="width: 130px;">{{ item.title }}</span> 
        <span style="width: 30px;margin-left: 5px;">{{ item.pageNo }}</span>
      </div>
    </div>
  </div> -->
  <div class="book-catalogue-item" :class="[open && 'open']">
    <div class="book-catalogue-title fs-flex fs-flex-between" @click="select(data.pageNo)">
      <span style="width:160px;display: flex;">
        <el-icon v-if="data.children" class="book-catalogue-arrow" @click="handOpen">
          <CaretRight />
        </el-icon>
        <!-- <el-icon v-else class="book-catalogue-arrow"> </el-icon> -->
        <div v-else>·</div>
        <span>{{ data.title }}</span>
      </span>
      <span style="width:48px;margin-left: 10px;padding-left: 10px">{{ data.pageNo }}</span>
    </div>
    <div class="book-catalogue-item2" v-for="(item, index) in data.children" :key="index"
      :class="[openChild&&(index===chooseIndex) && 'open2']">
      <div class="book-catalogue-title2 fs-flex fs-flex-between" @click="select(item.pageNo)">
        <span style="width:146px;display: flex;">
          <el-icon v-if="item.children" class="book-catalogue-arrow2" @click="handOpenChild(index)">
            <CaretRight />
          </el-icon>
          <!-- <el-icon v-else class="book-catalogue-arrow2"> </el-icon> -->
          <span>{{ item.title }}</span>
        </span>
        <span style="width:48px;margin-left: 10px;padding-left: 10px">{{ item.pageNo }}</span>
      </div>
      <div class="book-catalogue-child">
        <div v-for="(childItem, index) in item.children" :key="index" class="fs-flex fs-flex-between"
          @click="select(childItem.pageNo)">
          <span style="width: 132px;">{{ childItem.title }}</span>
          <span style="width:48px;margin-left: 10px;padding-left: 10px;">{{ childItem.pageNo }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref, defineProps, onMounted } from "vue";

export default {
  props: {
    data: Object,
  },

  data() {
    return {
      open: false,
      openChild: false,
      chooseIndex:-1,
    }
  },

  mounted() {
    // 获取品牌logo
    //console.log(123, this.$props.data);
  },

  methods: {
    handOpen() {
      if (this.$props.data.children) {
        this.open = !this.open;
      }
    },
    handOpenChild(index) {
      if (this.$props.data.children[index].children) {
        this.openChild = !this.openChild;
        this.chooseIndex = index;
      }
    },
    select(pageNo) {
      console.log("outline item selected...  ", pageNo);
      this.$emit("select", pageNo);
    },
  },
};

// const props = defineProps({
//   data: Object,
// });

// const open = ref(false);
// onMounted(async () => {
//   // 获取品牌logo
//   console.log(123, props.data);
// });

// const handOpen = () => {
//   if (props.data.children) {
//     open.value = !open.value;
//   }
// };

// const select = function (pageNo) {
//   this.$emit("select", pageNo);
// };
</script>
<style lang="less" scoped>
// .book-catalogue-item {
//   width: 200px;
//   font-size: 16px;
//   /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
//   font-weight: 400;
//   color: #4f4f4f;
//   line-height: 23px;
//   display: flex;
//   flex-direction: column;
//   flex-wrap: nowrap;
//   justify-content: center;
//   align-items: stretch;

//   margin-right: 7px;

//   &:hover {
//     .book-catalogue-title {
//       cursor: pointer;
//       color: #00a0e9;
//     }
//   }

//   .book-catalogue-child {
//     padding-left: 28px;
//     color: #4f4f4f;
//     font-size: 14px;
//     max-height: 0;
//     overflow: hidden;
//     transition: all 0.5s;

//     &>div {
//       margin-top: 14px;

//       &:hover {
//         cursor: pointer;
//         color: #00a0e9;
//       }
//     }
//   }
// }
.book-catalogue-item {
  width: 200px;
  font-size: 16px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #4f4f4f;
  line-height: 23px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;

  &:hover {
    .book-catalogue-title {
      cursor: pointer;
      color: #00a0e9;
    }
  }
}
.book-catalogue-item2{
  width: 200px;
  
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #4f4f4f;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  padding-left: 14px;
  font-size: 14px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
  

  .book-catalogue-title2 {
    margin-top: 14px;

    span{
      word-break:normal;
    }
  }

  &:hover {
    .book-catalogue-title2 {
      cursor: pointer;
      color: #00a0e9;
    }
  }

  .book-catalogue-child {
    padding-left: 14px;
    color: #4f4f4f;
    font-size: 14px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;

    &>div {
      margin-top: 14px;

      &:hover {
        cursor: pointer;
        color: #00a0e9;
      }
    }
  }
}
// .open {
//   color: #00a0e9;

//   .book-catalogue-child {
//     max-height: 1000px;
//   }

//   .book-catalogue-arrow {
//     transform: rotate(90deg);
//     transition: all 0.2s;
//   }
// }
.open {
  color: #00a0e9;

  .book-catalogue-item2 {
    max-height: 1000px;
  }

  .book-catalogue-arrow {
    transform: rotate(90deg);
    transition: all 0.2s;
  }
}
.open2 {
  color: #00a0e9;

  .book-catalogue-child {
    max-height: 1000px;
  }

  .book-catalogue-arrow2 {
    transform: rotate(90deg);
    transition: all 0.2s;
  }
}
</style>
