<template>
  <div class="book-catalogue">
    <Item v-for="(item, index) in data" :key="index" :data="item" @select="select" />
  </div>
</template>

<script>
import Item from "./item";
// import { defineProps, onMounted } from 'vue'

export default {
  components: {
    Item,
  },

  props: {
    data: Object,
  },

  mounted() {
    console.log(123, this.$props.data);
  },

  methods: {
    select(pageNo) {
      console.log("outline selected...  pageNo=", pageNo);
      this.$emit("select", pageNo);
    },
  },
};

// const props = defineProps({
//     data: Object,
// });
// onMounted(async () => {
//     // 获取品牌logo
//     console.log(123, props.data);

// })

// const select = function(pageNo){
//     this.$emit("select", pageNo);
// }
</script>
<style lang="less" scoped>
.book-catalogue {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  row-gap: 16px;
  overflow: auto;
  height: 95%;
  padding: 0 30px;
}
.book-catalogue::-webkit-scrollbar {
      //display: none;
    width: 5px;
    height: 5px;
}

/*滚动条滑块*/
.book-catalogue::-webkit-scrollbar-thumb{
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #c7c4c4;
}
/*滚动条里面轨道*/
.book-catalogue::-webkit-scrollbar-track {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
 
}
/*滚动条的小边角*/
.book-catalogue::-webkit-scrollbar-corner {
    background: transparent;
}

</style>
